import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@mui/material/Typography';
import { red } from '@material-ui/core/colors';
import PriceTable from '../priceTable/PriceTable';
import Modal from '@material-ui/core/Modal';
import { Box } from '@material-ui/core';
import ShopImageList from './ShopImageList';
import { Stack } from '@mui/material';
import OptionTable from '../priceTable/OptionTable';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "8em",
  },
  rootList: {
    // maxWidth: "95%",
  },
  avatar: {
    backgroundColor: red[500],
  },
  media: {
    height: "4.5em",
    // paddingTop: '56.25%', // 16:9
  },
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "75%",
  height: "98%", 
  maxWidth: "540px",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CardShop({
  currGroup, currProduct, item, handleAddToCart
}) {
  // console.log("url", item);

  const classes = useStyles();
  const [pickSize, setPickSize] = React.useState(0);
  const [pickPrice, setPickPrice] = React.useState('');
  const [pickOption, setPickOption] = React.useState(0);
  


  // console.log("currProduct", currProduct);

  const handlePickSize = (event, newPickSize) => {
    setPickSize(newPickSize);
    // console.log("pickSize:", newPickSize)
  };
  
  const handlePickOption = (event, newPickOption) => {
    setPickOption(newPickOption);
    // console.log("pickOption:", newPickOption)
  };


  useEffect(()=>{
    if (pickSize !== null && pickOption !== null) {
      setPickPrice(currProduct.prices[pickSize]);
    } else {
      setPickPrice("select a size & option");
    };
    // console.log("card: ", pickPrice);
  },[pickSize, currProduct.prices, pickOption])

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => { setOpen(true) };
  const handleClose = () => { setOpen(false) };

  const learnMore = (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h4" id="modal-title">{item.name}</Typography>
          <Typography variant="h6" id="modal-description">
            {item.desc}
          
          </Typography>
          <Box>
            <ShopImageList images={item.images} />
          </Box>

          <Typography variant="body1">
          Size: {currProduct.sizes[pickSize]},   {currProduct.details[pickSize]}
          </Typography>
          
          <Typography variant="h6">Price: Cdn$ {pickPrice}</Typography>


          <Stack spacing={2} direction="row">
            <Button size="medium" color="secondary" variant="contained"
              key={item.code}
              onClick={ (event) => {
                handleAddToCart(event, item, pickSize);
              } } >
              Add to Cart
            </Button>
            <Button size="small" color="secondary" variant="outlined"
              onClick={ () => handleClose() } >
              Continue Shopping
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );

  // console.log("currProduct.option:", currProduct.option == '')
  
  return (
    <Card sx={{ maxWidth: 345 }}>

        <CardMedia className={classes.media}
          component="img"
          alt={item.name}
          // height="240"
          image={item.images[0]}
          title={item.name}
        />
        <CardContent>
        <Typography gutterBottom variant="h5" component="div" align='left'>
          {item.name}
          </Typography>
        <Typography variant="body1" color="text.secondary" align="left">
          {item.desc}
          </Typography>

          <PriceTable 
            currProduct={currProduct} 
            item={item}
            pickSize={pickSize}
            handlePickSize={handlePickSize} />

          <Typography variant="body1" align="left">
            {currProduct.details[pickSize]}
          </Typography>

          {/* only display if there is an option */}
          {currProduct.option !== '' && (
            <OptionTable 
            currProduct={currProduct} 
            item={item}
            pickOption={pickOption}
            handlePickOption={handlePickOption} />    
          )}
          
        

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Price: Cdn$ {pickPrice}</Typography>
          </Box>

        </CardContent>

      {/* </CardActionArea> */}
      <CardActions>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button size="medium" color="secondary" variant="contained"
          key={item.code}
          onClick={ (event) => 
          handleAddToCart(event, item, pickSize, pickOption) } >
          Add to Cart
        </Button>

           

        <Button size="small" color="secondary" variant="outlined"
          key={item.code}
          onClick={ (event) => handleOpen(event, item, pickSize) } >
          Learn More
        </Button>

        {learnMore}
      </Box>

      </CardActions>


    </Card>
  );
}
