import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles({
  table: {
    width: "100%",
    fontSize: "0.9rem",
    fontFamily: 'Josefin Sans',
  },
});

function OptionTable({currProduct, item, pickOption, handlePickOption}) {
  const classes = useStyles();
  const stocks = item.stocks;
  const option = currProduct.option;
  const sizes = currProduct.sizes;

  // coma seperated field to array
  const array = currProduct.choices.split(',').map(item => item.trim());


  // console.log("option table current product", array);

  return (
    <div>

      <Box 
        className={classes.table}
        sx={{ mt: 2, display: 'flex', alignItems: 'center' }}
        >

        <Typography variant="h6" >{currProduct.option}: </Typography>

        <ToggleButtonGroup
          value={pickOption}
          exclusive
          onChange={handlePickOption}
          aria-label="text alignment"
        >

        {array.map((size, index)=>
          (( size !== "" ) &&
            <ToggleButton value={index} key={size} style={{textTransform: 'none'}}>
              <Typography align="right" key={size}>{size}</Typography>
            </ToggleButton>
          )
        )}

        </ToggleButtonGroup>



        </Box>

      
    </div>
  );
}

export default OptionTable;

