import { auth } from "../firebase/config";
import { getAuth } from "firebase/auth";

async function getUser(user, setUser) {
  const auth = getAuth();

  const autUser = auth.currentUser;
  console.log("autUser: ", autUser);

  if (autUser) {
    setUser(autUser.email);
  } else {
    setUser("none");
  }

  return user;
}

function logout(setUser) {
  setUser("none");
  return auth.signOut();
}

export { getUser, logout };
