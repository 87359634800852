import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const useStyles = makeStyles({
  table: {
    width: "100%",
    fontSize: "0.9rem",
    fontFamily: 'Josefin Sans',
  },
});

function PriceTable({currProduct, item, pickSize, handlePickSize}) {
  const classes = useStyles();
  const stocks = item.stocks;
  const sizes = currProduct.sizes;

  return (
    <div>
      
      <Box 
        className={classes.table}
        sx={{ mt: 2, display: 'flex', alignItems: 'center' }}
        >

        <Typography variant="h6" sx={{ mr: 2 }}>Size: </Typography>

        <ToggleButtonGroup
          value={pickSize}
          exclusive
          onChange={handlePickSize}
          aria-label="size"
        >

        {sizes.map((size, index)=>
          ((size !== "" && Number(item.material)+Number(item.stocks[index]) >0 ) &&
            <ToggleButton value={index} key={size}>
              <Typography align="right" key={size}>{size}</Typography>
            </ToggleButton>
          )
        )}

        </ToggleButtonGroup>

      </Box>

      
    </div>
  );
}

export default PriceTable;

